<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap40UpgradingOrDowngradingGo"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 40: Upgrading or Downgrading Go" image-name="upgrade-downgrade.jpg" image-alt="Upgrading or Downgrading Go"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li>How to upgrade (or downgrade) Go on your computer.</li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li>SHA256</li>
</ul>
<div id="checking-your-version" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Checking your version <a href="#checking-your-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To get the currently installed version of Go, just type :</p>
<pre v-highlightjs><code class="go" v-pre >$ go version</code></pre>
<p>This will output something like that :</p>
<pre v-highlightjs><code class="go" v-pre >go version go1.11.1 darwin/amd64</code></pre>
<p>The version is printed with the reference of your system (in my case Apple MacOS)</p>
<div id="where-is-your-go-binary" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Where is your Go binary? <a href="#where-is-your-go-binary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To upgrade your system to a new version of go, you have to locate where Go is on your system.</p>
<pre v-highlightjs><code class="go" v-pre >$ which go</code></pre>
<p>Wich will locate the program file in the user path (cf. man which)</p>
<p>Usually (Linux and Mac users), if you type this on your system, it will display :</p>
<pre v-highlightjs><code class="go" v-pre >/usr/local/go/bin/go</code></pre>
<p>For windows users, the path where the go binaries are stored is usually.</p>
<pre v-highlightjs><code class="go" v-pre >C:\Go\bin</code></pre>
<p>To be sure, you can run the command :</p>
<pre v-highlightjs><code class="go" v-pre >c:\&gt; where go</code></pre>
<p>We have successfully located the binaries; now we can upgrade it</p>
<div id="download-a-new-version" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Download a new version <a href="#download-a-new-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>New versions of Go are hosted on the golang.org website here: https://golang.org/dl/. Always download your go version from this official URL.</p>
<p>To update your current version, download on this website the targeted version.</p>
<p>To be sure that you have downloaded a valid binary, you have to compute the SHA256 sum of the downloaded file. Then you have to ensure that it is strictly equal to the one that is listed on the website (see <a href="#fig:Webpage-to-download" data-reference-type="ref" data-reference="fig:Webpage-to-download">1</a>).</p>
<figure>
<b-img :src="require('@/assets/images/go_download_version.png')" alt="Webpage to download go binaries[fig:Webpage-to-download]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Webpage to download go binaries<span id="fig:Webpage-to-download" label="fig:Webpage-to-download">[fig:Webpage-to-download]</span></figcaption>
</figure>
<div id="on-macos" class="anchor"></div>
<h4 data-number="5.0.0.1"><span class="header-section-number">5.0.0.1</span> On macOS <a href="#on-macos"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>To check a sha256 sum, open a terminal and type :</p>
<pre v-highlightjs><code class="go" v-pre >$ shasum -a 256 go1.11.2.linux-amd64.tar.gz</code></pre>
<p>The a flag is used to indicate which algorithm to use (here it’s SHA256)</p>
<div id="on-linux" class="anchor"></div>
<h4 data-number="5.0.0.2"><span class="header-section-number">5.0.0.2</span> On Linux <a href="#on-linux"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The command is shorter :</p>
<pre v-highlightjs><code class="go" v-pre >$ sha256sum go1.11.2.linux-amd64.tar.gz</code></pre>
<div id="on-windows" class="anchor"></div>
<h4 data-number="5.0.0.3"><span class="header-section-number">5.0.0.3</span> On Windows <a href="#on-windows"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>You can use certutil to do so on the command prompt</p>
<pre v-highlightjs><code class="go" v-pre >&gt; certutil -hashfile go1.11.2.linux-amd64.tar.gz SHA256</code></pre>
<div id="install-the-newold-version" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Install the (new/old) version <a href="#install-the-newold-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="on-macos-1" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> On macOS <a href="#on-macos-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You downloaded an installer. It will automatically install the selected version. Just double-click on the file downloaded.</p>
<div id="on-linux-1" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> On Linux <a href="#on-linux-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="archive-extraction" class="anchor"></div>
<h5 data-number="6.2.0.0.1"><span class="header-section-number">6.2.0.0.1</span> Archive extraction <a href="#archive-extraction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<p>First, you have to extract the archive downloaded with the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ sudo tar -C /usr/local -xzf go1.11.2.linux-amd64.tar.gz</code></pre>
<p>It will extract the contents of the tar.gz file into <span v-highlightjs><code class="go" v-pre style="display: inline">/usr/local</code></span> :</p>
<figure>
<b-img :src="require('@/assets/images/files_from_usr_local_go.png')" alt="Contents of /usr/local/go (Go v.1.16)"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Contents of /usr/local/go (Go v.1.16)</figcaption>
</figure>
<p>The <strong>bin</strong> directory contains two executables files at the time of writing :</p>
<ul>
<li><p>go</p></li>
<li><p>gofmt</p></li>
</ul>
<div id="path-modification" class="anchor"></div>
<h5 data-number="6.2.0.0.2"><span class="header-section-number">6.2.0.0.2</span> PATH modification <a href="#path-modification"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h5>
<p>To be able to launch go and gofmt commands, you will need to add this directory to your PATH. To do so, add this line at the top of the file <span v-highlightjs><code class="go" v-pre style="display: inline">.bashrc</code></span> located in your home directory. (or <span v-highlightjs><code class="go" v-pre style="display: inline">.zshrc</code></span> if you use zsh) :</p>
<pre v-highlightjs><code class="go" v-pre >export PATH=$PATH:/usr/local/go/bin</code></pre>
<p>After that, you will need to restart your terminal for the change to take effect.</p>
<div id="on-windows-1" class="anchor"></div>
<h2 data-number="6.3"><span class="header-section-number">6.3</span> On Windows <a href="#on-windows-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You downloaded an installer. it will automatically install the selected version. Just double-click on the file downloaded.</p>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap40UpgradingOrDowngradingGo"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap39AnObjectOrientedProgrammingLanguage'}">
									<p><u><small>Previous</small></u></p>
									<p><small>An object oriented programming language ?</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap41DesignRecommendations'}">
									<p><u><small>Next</small></u></p>
									<p><small>Design Recommendations</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Upgrading or Downgrading Go - Practical Go Lessons"
const description = "How to upgrade or downgrade your Go versions with MacOS, Windows"

export default {
  name: "Chap40UpgradingOrDowngradingGo",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
